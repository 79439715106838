
import { type ReactNode, type HTMLProps, type ForwardedRef , forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type InputContainerProps = {
	className?: string
	children: ReactNode
	orientation?: 'vertical' | 'horizontal'
} & HTMLProps<HTMLDivElement>

const InputContainer = forwardRef(function ({ className, children, orientation, ...props }: InputContainerProps, ref: ForwardedRef<HTMLDivElement> | undefined) {
	const inputContainerClasses = `flex ${orientation !== 'horizontal' ? 'flex-col gap-1.5 4xl:gap-2' : 'gap-2 4xl:gap-3 items-center'} w-full`

	const classNames = twMerge(`${inputContainerClasses}`, className)

	return (
		<div {...props} className={classNames} ref={ref}>
			{children}
		</div>
	)
})

InputContainer.displayName = 'InputContainer'

export default InputContainer
