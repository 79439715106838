import React,  { type ReactNode, type HTMLProps, type ForwardedRef , forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type LabelProps = {
	className?: string
	error?: boolean
	children: ReactNode
} & HTMLProps<HTMLLabelElement>

const Label = forwardRef(function ({ className, error, children, ...props }: LabelProps, ref: ForwardedRef<HTMLLabelElement> | undefined) {

	const labelClasses = `
	font-medium min-w-max
    ${error ? 'text-danger' : 'text-muted-600'}
  `

	const classNames = twMerge(`${labelClasses}`, className)

	return (
		<label {...props} className={classNames} ref={ref}>
			{children}
		</label>
	)
})

Label.displayName = 'Label'

export default Label
